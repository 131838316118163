import { Fragment } from "react";
// images
import avatar2 from "assets/images/avatar-1.jpg";
import avatar10 from "assets/images/MasMoneyLogo.png";
import InfiniteScroll from "react-infinite-scroll-component";
import avatar1 from "assets/images/avatar-1.jpg";
// Components
import UserMessage from "./UserMessage";
// Interfaces
import { ChatUserType } from "interfaces/ChatUserType";
import { MessageItem } from "interfaces/MessageItem";
// Constants
import {
  CHAT_LIMIT_REGISTER_USER,
  CHAT_LIMIT_SUBSCRIPTION_USER,
} from "utils/constants";

export interface TemporalMessage {
  temporalMessage: string;
}

export interface ChatMessage {
  id: number;
  day: string;
  messages: MessageItem[];
}
interface ChatHistoryComponentProps {
  getNextMessages: () => void;
  historial: ChatMessage[];
  hasMore: boolean;
  messageCount: number | null;
  isUserLoggedIn: boolean | null;
  aiThinking: boolean | null;
  maxChatMessages: number | null;
  scrollRef: any;
}
// Users
export const MasMoneyCoach: ChatUserType = {
  id: 2,
  name: "Más Money Coach",
  avatar: avatar10,
  userStatus: "online",
};
export const Jimmy: ChatUserType = {
  id: 9,
  name: "Jimmy",
  avatar: avatar2,
};

const toUser = {
  id: 9,
  name: "Shreyu N",
  avatar: avatar1,
};

const ChatHistoryComponent: React.FC<ChatHistoryComponentProps> = ({
  getNextMessages,
  historial,
  hasMore,
  messageCount,
  isUserLoggedIn,
  aiThinking,
  maxChatMessages,
  scrollRef,
}) => {
  let totalSum = 0;
  for (const arr of historial) {
    totalSum += arr.messages.length;
  }

  const TemporalMessage = () => {
    let temporalMessage: any = null;
    if (messageCount === CHAT_LIMIT_REGISTER_USER && !isUserLoggedIn) {
      temporalMessage = (
        <ul className="conversation-list px-0 h-100">
          <div className="ctext-wrap">
            <p>
              ¡Aún puedes realizar 8 preguntas más, 100% gratuitas! Sólo debes
              registrarte para continuar :)
            </p>
          </div>
        </ul>
      );
    } else if (
      messageCount === CHAT_LIMIT_SUBSCRIPTION_USER &&
      isUserLoggedIn
    ) {
      temporalMessage = (
        <ul className="conversation-list px-0 h-100">
          <div className="ctext-wrap">
            <p>
              ¡Tus preguntas estuvieron muy buenas! Ahora tienes la oportunidad
              de acceder a un chat mucho más personalizado, con planes de acción
              en base a tus intereses e incluso podrás optar por agendar una
              asesoría online 💯
            </p>
          </div>
        </ul>
      );
    } else if (messageCount === maxChatMessages) {
      temporalMessage = (
        <ul className="conversation-list px-0 h-100 mb-4">
          <div className="ctext-wrap">
            <p>
              ¡Oops! Parece que has alcanzado el límite de preguntas de tu plan
              actual. No te preocupes, puedes esperar hasta el próximo mes para
              hacer más preguntas, o si tienes interés en obtener un plan
              personalizado, puedes escribirnos a hello@mas.money, estaremos
              encantados de ayudarte.
            </p>
          </div>
        </ul>
      );
    }
    return temporalMessage;
  };

  return (
    <InfiniteScroll
      className="conversation-list"
      dataLength={totalSum}
      next={getNextMessages}
      hasMore={hasMore}
      loader={
        <p className="d-flex justify-content-center my-2">
          Cargando más mensajes...
        </p>
      }
      endMessage={
        totalSum > 2 && (
          <p className="d-flex justify-content-center mb-2">
            No hay más mensajes por mostrar :)
          </p>
        )
      }
      scrollableTarget="scrollableDiv"
      inverse={true}
      style={{
        overflow: "hidden",
        display: "flex",
        flexDirection: "column-reverse",
      }}
    >
      {!aiThinking &&
      (messageCount === CHAT_LIMIT_REGISTER_USER ||
        (messageCount === CHAT_LIMIT_SUBSCRIPTION_USER && !maxChatMessages) ||
        messageCount === maxChatMessages) ? (
        <TemporalMessage />
      ) : null}

      {historial.map((item, index) => {
        return (
          <Fragment key={`${index}`}>
            {(item.messages || []).map((message, index) => {
              return (
                <Fragment key={`${index}-${message}`}>
                  <UserMessage message={message} toUser={toUser} />
                </Fragment>
              );
            })}
            <li className="position-relative">
              {item.messages.length > 1 && <hr />}
              <h4>
                <span
                  style={{ top: "8px !important" }}
                  className="badge bg-light text-dark position-absolute start-50 translate-middle"
                >
                  {item.day}
                </span>
              </h4>
            </li>
          </Fragment>
        );
      })}
    </InfiniteScroll>
  );
};
export default ChatHistoryComponent;
