import axios, { AxiosRequestConfig } from "axios";

const cookies: string = document.cookie;
const csrfToken: string | undefined = cookies.match(/csrftoken=([\w-]+)/i)?.[1];
const headers: Record<string, string> = {
  "Content-Type": "application/json",
  "X-CSRFToken": csrfToken!,
};

export const http = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  headers: headers,
  withCredentials: true,
});

export async function get<T>(
  url: string,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await http.get<T>(url, config);
  return response.data;
}

export async function post<T>(
  url: string,
  data?: any,
  config?: AxiosRequestConfig
): Promise<T> {
  const response = await http.post<T>(url, data, config);
  return response.data;
}
