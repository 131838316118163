import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "assets/styles/FooterMessage.css";

import { CHAT_LIMIT_SUBSCRIPTION_USER } from "utils/constants";

interface FooterMessageProps {
  messageCount: number;
}

const FooterMessage = ({ messageCount }: FooterMessageProps) => {
  const navigate = useNavigate();

  const handleContinueClick = () => {
    const url = `${process.env.REACT_APP_BASE_API_URL}/register/?next=masmoneychat`;
    window.location.href = url;
  };

  const handleSubscriptionClick = () => {
    const url = `/planes`;
    navigate(url);
  };

  return (
    <Fragment>
      <div
        className="bg-light chatbot_footer col-lg-8 mx-auto border-start border-end align-items-center justify-content-center d-flex"
        style={{ height: 98 }}
      >
        <div className="rounded px-4 w-100">
          <form noValidate name="chat-form" id="chat-form">
            <div className="align-items-center">
              <div className="col-sm-auto text-center">
                <div className="btn-group mx-auto text-center button-msg">
                  <button
                    type="button"
                    onClick={
                      messageCount === CHAT_LIMIT_SUBSCRIPTION_USER
                        ? handleSubscriptionClick
                        : handleContinueClick
                    }
                    className="btn chat-send-2 d-flex align-items-center justify-content-center"
                  >
                    <span className="fw-bold">
                      {messageCount >= CHAT_LIMIT_SUBSCRIPTION_USER
                        ? "¡Quiero!"
                        : "Continuar"}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </form>
          <div
            style={{ position: "absolute", right: "86px" }}
            className="counter-container"
          ></div>
        </div>
      </div>
    </Fragment>
  );
};

export default FooterMessage;
