import React, { Fragment, useState, useEffect, useRef } from "react";
// React Hook Form
import { SubmitHandler } from "react-hook-form";
// React-bootstrap
import { Card } from "react-bootstrap";
// Service
import { get, post } from "Service/apiCall";
import getChatHistory from "Service/getChatHistory";
import getNextPage from "Service/getNextPage";
import getIntroductoryMessages from "Service/getIntroductoryMessages";
// css
import "../assets/styles/ChatArea.css";
// images
import avatar2 from "assets/images/avatar-1.jpg";
import avatar10 from "assets/images/MasMoneyLogo.png";
import avatar1 from "assets/images/avatar-1.jpg";
// Interface
import { ChatUserType } from "interfaces/ChatUserType";
import { MessageItem } from "interfaces/MessageItem";
// components
import ChatHistoryComponent from "components/ChatHistory";
import ChatHeader from "components/ChatHeader";
import ChatFooter from "components/ChatFooter";
import FooterMessage from "components/FooterMessage";
import Loader from "./Loader";
// Custom Hooks
import { useLocalStorage } from "hooks/useLocalStorage";
import { userData } from "views/ChatToUser/ChatToUser";
// Constants
import {
  CHAT_LIMIT_REGISTER_USER,
  CHAT_LIMIT_SUBSCRIPTION_USER,
} from "utils/constants";

interface ChatAreaProps {
  selectedUser: ChatUserType;
}

const cookies = document.cookie;
const csrfToken = cookies.match(/csrftoken=([\w-]+)/i)?.[1];
const headers = {
  "Content-Type": "application/json",
  "X-CSRFToken": csrfToken,
};
interface HttpResponse<T = any> {
  data: T;
  answer: string;
  status: number;
  statusText: string;
  headers: any;
}
interface Payload {
  question: string;
}

const askGtp = async (message: string | undefined): Promise<HttpResponse> => {
  let payload: Payload = {
    question: message || "",
  };
  try {
    const response: HttpResponse = await post("/api/call-gpt/", payload, {
      headers,
    });
    return response;
  } catch (error: any) {
    // :TODO: Aca se puede poner un modal o un mensaje para informar al usuario
    return error;
  }
};

type newMessageType = {
  newMessage: string;
};

export interface ChatMessage {
  id: number;
  day: string;
  messages: MessageItem[];
}

export const MasMoneyCoach: ChatUserType = {
  id: 2,
  name: "Más Money Coach",
  avatar: avatar10,
  userStatus: "online",
};
export const Jimmy: ChatUserType = {
  id: 9,
  name: "Jimmy",
  avatar: avatar2,
};

const ChatArea = ({ selectedUser }: ChatAreaProps) => {
  const [aiThinking, setAiThinking] = useState<boolean>(false);
  const [results, setResults] = useState<any>([]);
  const [next, setNext] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [historial, setHistorial] = useState<ChatMessage[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const yesterdayMessages: MessageItem[] = [];
  const todayMessages: MessageItem[] = [];
  const messagesByDate: { [date: string]: MessageItem[] } = {};
  const [userData, setUserData] = useState<userData | null>(null);
  const [messageCount, setMessageCount] = useLocalStorage("messageCount", 0);
  const isUserLoggedIn = userData && userData.id >= 0;
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const toUser = {
    id: 9,
    name: "Shreyu N",
    avatar: avatar1,
  };

  const getUserData = async () => {
    const user: any = await get(`/api/profile`);
    setUserData(user);
  };

  const sendChatMessage: SubmitHandler<Partial<newMessageType>> = async (
    values
  ) => {
    setAiThinking(true);

    const chatHistoryIsEmpty: boolean = historial.length === 0;

    let newUserMessages: MessageItem[] = [];
    if (!chatHistoryIsEmpty) {
      newUserMessages = [...historial[0].messages];
      newUserMessages.unshift({
        id: historial[0].messages.length + 1,
        from: toUser,
        to: selectedUser,
        messages: [{ type: "text", value: values["newMessage"] }],
        sendOn:
          new Date().getHours() +
          ":" +
          (new Date().getMinutes() < 10 ? "0" : "") +
          new Date().getMinutes() +
          (new Date().getHours() <= 12 ? " am" : " pm"),
      });
    } else {
      newUserMessages.push({
        id: 1,
        from: toUser,
        to: selectedUser,
        messages: [{ type: "text", value: values["newMessage"] }],
        sendOn:
          new Date().getHours() +
          ":" +
          (new Date().getMinutes() < 10 ? "0" : "") +
          new Date().getMinutes() +
          (new Date().getHours() <= 12 ? " am" : " pm"),
      });
    }

    let modifiedChatHistory: any = [];

    if (!chatHistoryIsEmpty) {
      modifiedChatHistory = [...historial].map((record, index) => {
        const test = {
          id: record.id,
          day: record.day,
          messages: index === 0 ? newUserMessages : record.messages,
        };
        return test;
      });
    } else {
      modifiedChatHistory = [
        {
          id: "3",
          day: "Hoy",
          messages: newUserMessages,
        },
      ];
    }

    setHistorial([...modifiedChatHistory]);
    const response = await askGtp(values["newMessage"]);

    newUserMessages.unshift({
      id:
        modifiedChatHistory[modifiedChatHistory.length - 1].messages.length + 1,
      from: selectedUser,
      to: toUser,
      messages: [{ type: "text", value: response.answer }],
      sendOn:
        new Date().getHours() +
        ":" +
        (new Date().getMinutes() < 10 ? "0" : "") +
        new Date().getMinutes() +
        (new Date().getHours() <= 12 ? " am" : " pm"),
    });
    setHistorial([...modifiedChatHistory]);

    setAiThinking(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      await getMessages();
      setLoading(false);
    };
    getUserData();
    fetchData();
  }, []);

  useEffect(() => {
    chatContainerRef?.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight + 10,
      behavior: "smooth",
    });
  }, [historial]);

  useEffect(() => {
    let CHATHISTORY: ChatMessage[] = [];
    for (const result of results) {
      const messageTime: number = result.time;
      const messageDate: Date = new Date(messageTime);
      const currentDate: Date = new Date();
      // Convertir la fecha de time a un string en formato 'YYYY-MM-DD'
      const dateString: string = messageDate.toString().slice(0, 10);
      // Convertir la fecha actual a un string en formato 'YYYY-MM-DD'
      const currentDateString: string = currentDate.toString().slice(0, 10);
      let hours = messageDate.getHours();
      let minutes = messageDate.getMinutes();
      let timePeriod = "am";
      if (hours > 12) {
        hours -= 12;
        timePeriod = "pm";
      }
      if (minutes === 0) {
        hours = 12;
      }
      const formattedTime = `${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${timePeriod}`; // E.g. 8:30 am

      if (dateString === currentDateString) {
        // Si la fecha del dato es igual a la fecha actual, es 'hoy'
        todayMessages.push(
          {
            id: result._id,
            messages: [
              {
                type: "text",
                value: result.answer,
              },
            ],
            to: Jimmy,
            from: MasMoneyCoach,
            sendOn: formattedTime,
          },
          {
            id: result._id,
            messages: [
              {
                type: "text",
                value: result.question,
              },
            ],
            to: MasMoneyCoach,
            from: Jimmy,
            sendOn: formattedTime,
          }
        );
      } else {
        // Si la fecha del dato no es igual a la fecha actual, comprobar si es de ayer o antes de ayer
        if (currentDate.getDate() - messageDate.getDate() === 1) {
          // Si la diferencia en días es igual a 1, el dato es 'ayer'
          yesterdayMessages.push(
            {
              id: result._id,
              messages: [
                {
                  type: "text",
                  value: result.answer,
                },
              ],
              to: Jimmy,
              from: MasMoneyCoach,
              sendOn: formattedTime,
            },
            {
              id: result._id,
              messages: [
                {
                  type: "text",
                  value: result.question,
                },
              ],
              to: MasMoneyCoach,
              from: Jimmy,
              sendOn: formattedTime,
            }
          );
        } else {
          // Si la diferencia en días es mayor a 1, el dato es de una fecha anterior a antes de ayer
          const messageTime: number = result.time;
          const messageDate: Date = new Date(messageTime);
          // Convertir la fecha de time a un string en formato 'DD_MM_YYYY'
          const dateString: string = messageDate
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, "_")
            .replace(/(\d{2})_(\d{2})_(\d{4})/, "$2_$1_$3");
          // Agregar el mensaje al array correspondiente a la fecha
          if (!messagesByDate[dateString]) {
            messagesByDate[dateString] = [];
          }
          messagesByDate[dateString].push(
            {
              id: result._id + 1,
              messages: [
                {
                  type: "text",
                  value: result.answer,
                },
              ],
              to: Jimmy,
              from: MasMoneyCoach,
              sendOn: formattedTime,
            },
            {
              id: result._id,
              messages: [
                {
                  type: "text",
                  value: result.question,
                },
              ],
              to: MasMoneyCoach,
              from: Jimmy,
              sendOn: formattedTime,
            }
          );
        }
      }
    }
    // Agregar los mensajes por fecha a CHATHISTORY
    if (todayMessages.length > 0) {
      CHATHISTORY.push({
        id: 3,
        day: "Hoy",
        messages: todayMessages,
      });
    }
    if (yesterdayMessages.length > 0) {
      CHATHISTORY.push({
        id: 2,
        day: "Ayer",
        messages: yesterdayMessages,
      });
    }
    for (const date in messagesByDate) {
      CHATHISTORY.push({
        id: parseInt(date),
        day: date.replace(/_/g, "/"),
        messages: messagesByDate[date],
      });
    }
    setHistorial(CHATHISTORY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const getNextMessages = async () => {
    if (next) {
      const data: any = await getNextPage(next);
      setResults((prevResults: any) => [...prevResults, ...data.results]);
      setNext(data.next);
      setHasMore(data.next ? true : false);
    } else {
      setHasMore(false);
    }
  };

  const getMessages = async () => {
    const data = await getChatHistory();

    if (data.count === "0") {
      let CHATHISTORY: ChatMessage[] = [];

      getIntroductoryMessages().then((response) => {
        for (const item of response) {
          todayMessages.push({
            id: item.id,
            messages: [
              {
                type: "text",
                value: item.text,
              },
            ],
            to: Jimmy,
            from: MasMoneyCoach,
          });
        }

        CHATHISTORY.push({
          id: 3,
          day: "Hoy",
          messages: todayMessages,
        });
        setHistorial(CHATHISTORY);
        return;
      });
    }

    setHasMore(data.next ? true : false);
    setResults(data.results || []);
    setNext(data.next);
  };

  const renderFooter = () => {
    if (
      !aiThinking &&
      !loading &&
      ((!isUserLoggedIn && messageCount >= CHAT_LIMIT_REGISTER_USER) ||
        (isUserLoggedIn && messageCount >= CHAT_LIMIT_SUBSCRIPTION_USER))
    ) {
      return <FooterMessage messageCount={messageCount} />;
    }
    return (
      <ChatFooter
        aiThinking={aiThinking}
        gptAnswer={null}
        asesorLoader={null}
        sendChatMessage={sendChatMessage}
        messageCount={messageCount}
        setMessageCount={setMessageCount}
        maxChatMessages={null}
        isAsesor={false}
        waitingAsesorResponse={false}
        historial={historial.length}
      />
    );
  };

  const textarea = document.querySelector(
    "textarea[name='newMessage']"
  ) as HTMLTextAreaElement;
  const footerElement = document.querySelector(".chatbot_footer");
  const footerHeight = footerElement?.getBoundingClientRect().height;

  const observer = new MutationObserver(() => {
    const newFooterHeight = footerElement?.getBoundingClientRect().height;
    const cardElement = document.getElementById("scrollableDiv");
    if (cardElement) {
      cardElement.style.height = `calc(100vh - ${newFooterHeight}px)`;
    }
  });
  if (textarea) {
    observer.observe(textarea, { attributes: true });
  }

  return (
    <Fragment>
      <div style={{ backgroundColor: "#EAF6FE", height: "100vh" }}>
        <Card
          className="mb-0 border-0"
          id="scrollableDiv"
          style={{
            height: `calc(100vh - ${footerHeight}px)`,
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
          ref={chatContainerRef}
        >
          <Card.Body>
            <div
              className="card-body chatbot_header border-bottom col-lg-8 mx-auto"
              style={{
                padding: "0 1.25rem",
              }}
            >
              <ChatHeader selectedUser={selectedUser} />
            </div>
            <div className="mt-5 chat-area">
              {loading ? (
                <Loader />
              ) : (
                <ChatHistoryComponent
                  getNextMessages={getNextMessages}
                  historial={historial}
                  hasMore={hasMore}
                  messageCount={messageCount}
                  isUserLoggedIn={isUserLoggedIn}
                  aiThinking={aiThinking}
                  maxChatMessages={null}
                  scrollRef={chatContainerRef}
                />
              )}
            </div>
          </Card.Body>
        </Card>
      </div>
      {renderFooter()}
    </Fragment>
  );
};
export { ChatArea };
