import { Row, Col } from "react-bootstrap";
import { Routes, Route, Link, Outlet } from "react-router-dom";
import { ChatArea } from "components/ChatArea";
import { MasMoneyCoach } from "./components/ChatHistory";
import { ChatUserType } from "interfaces/ChatUserType";
import ChatUsage from "views/ChatUsage";
import { ChatToUser } from "views/ChatToUser/ChatToUser";
import Plans from "views/Plans";

import "assets/scss/Theme.scss";
import { Fragment } from "react";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="uso-del-chat" element={<ChatUsage />} />
        <Route path="planes" element={<Plans />} />
        <Route path="chat/:username" element={<Home2 />} />
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
};

function Layout() {
  return (
    <div>
      <Outlet />
    </div>
  );
}

function Home() {
  const selectedUser: ChatUserType = MasMoneyCoach;
  return (
    <Fragment>
      <div style={{ backgroundColor: "#EAF6FE" }}>
        <Row className="d-flex justify-content-center mx-0">
          <Col className="px-0 col-12 col-lg-8 border-start border-end">
            <ChatArea selectedUser={selectedUser} />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

function Home2() {
  return (
    <Fragment>
      <div style={{ backgroundColor: "#EAF6FE" }}>
        <Row className="d-flex justify-content-center mx-0">
          <Col className="px-0 col-12 col-lg-8 border-start border-end">
            <ChatToUser />
          </Col>
        </Row>
      </div>
    </Fragment>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Ups! No encontramos nada por aquí.</h2>
      <p>
        <Link to="/">Ir al chat.</Link>
      </p>
    </div>
  );
}

export default App;
