import { Fragment } from "react";
import classNames from "classnames";

import { Card, Row, Col } from "react-bootstrap";
import { ChatUserType } from "interfaces/ChatUserType"
import { MessageItem } from "interfaces/MessageItem";

interface UserMessageProps {
  message: MessageItem;
  toUser: ChatUserType;
}

const UserMessage = ({ message, toUser }: UserMessageProps) => {
  return (
    <Fragment>
      {(message.messages || []).map((item, index) => {
        return (
          <li
            key={index}
            className={classNames("clearfix", {
              odd: message.from.id === toUser.id,
              "mb-1":
                message.messages.length > 1 &&
                index !== message.messages.length - 1,
            })}
          >
            <div className="conversation-text ms-0">
              <div
                className={classNames("d-flex", {
                  "justify-content-end": message.from.id === toUser.id,
                })}
              >
                {item.type === "text" && (
                  <div
                    dangerouslySetInnerHTML={{ __html: item.value }}
                    className="ctext-wrap"
                  />
                )}

                {item.type === "file" && (
                  <Card className="mb-1 shadow-none border text-start">
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <div className="avatar-sm bg-primary text-white">
                            <span className="avatar-title rounded">.ZIP</span>
                          </div>
                        </Col>
                        <Col className="ps-0">
                          {/* <Link to="#" className="text-muted fw-bold">
                            {item.value.file}
                          </Link> */}
                          {item.value.file}
                          <p className="mb-0">{item.value.size}</p>
                        </Col>
                        <Col className="col-auto">
                          {/* <Link to="#" className="ps-3 fs-24">
                            <i className="bi bi-arrow-down-square"></i>
                          </Link> */}
                          <i className="bi bi-arrow-down-square"></i>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                )}
              </div>
              {index === message.messages.length - 1 && (
                <p className="text-muted fs-12 mb-0 mt-1">
                  {message.sendOn}
                  {message.from.id === toUser.id && (
                    <i className="bi bi-check2-all ms-1 text-success"></i>
                  )}
                </p>
              )}
            </div>
          </li>
        );
      })}
    </Fragment>
  );
};

export default UserMessage;
