import React, { useState, useEffect } from "react";
import { STRIPE_PUBLIC_KEY, STRIPE_PRICING_TABLE_ID } from "utils/constants";
// Axios
import { get } from "Service/apiCall";
// Bootstrap
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
declare global {
  export namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": {
        key: string;
        "pricing-table-id": string | undefined;
        "publishable-key": string | undefined;
        "client-reference-id"?: string;
        "customer-email": string | undefined;
      };
    }
  }
}

interface userData {
  id: number;
  email: string;
}

const Plans = () => {
  const [userData, setUserData] = useState<userData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUserData = async () => {
      const user: any = await get(`/api/profile`);
      setUserData(user);
      setLoading(false);
    };

    setLoading(true);
    fetchUserData();
  }, []);

  return (
    <>
      {loading && <p>Cargando...</p>}
      {!loading && userData && userData?.id > 0 && (
        <stripe-pricing-table
          key="string"
          pricing-table-id={STRIPE_PRICING_TABLE_ID}
          publishable-key={STRIPE_PUBLIC_KEY}
          customer-email={userData?.email}
        ></stripe-pricing-table>
      )}
      {!loading && userData && userData?.id < 0 && (
        <div
          className="modal show"
          style={{
            height: "100vh",
            backgroundColor: "grey",
            display: "block",
            position: "initial",
            textAlign: "center",
          }}
        >
          <Modal.Dialog centered>
            <Modal.Header
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Modal.Title>Favor iniciar sesión</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>Favor inicie sesión para poder obtener una suscripción.</p>
            </Modal.Body>
          </Modal.Dialog>
        </div>
      )}
    </>
  );
};

export default Plans;
