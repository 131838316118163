import LoaderImg from "assets/gifs/loading.gif";

/**
 * Renders the preloader
 */
const Loader = () => {
  return (
    <div className="preloader" id="preloader">
      <div className="status" id="status">
        <div
          className="status d-flex justify-content-center align-content-center align-items-center"
          id="status"
        >
          <img src={LoaderImg} alt="Loading" style={{ width: "98px" }} />
        </div>
      </div>
    </div>
  );
};

export default Loader;
