import { IntroMessage } from "interfaces/IntroMessage";
import { get } from "../Service/apiCall";

const getIntroductoryMessages = async (): Promise<Array<IntroMessage>> => {
  const response: Array<IntroMessage> = await get(
    `/api/get-introductory-messages`
  );

  return response;
};

export default getIntroductoryMessages;
