import React from "react";
import { ChatUserType } from "interfaces/ChatUserType";

import BackBtn from "../assets/images/Icons/BackBtn.svg";

interface ChatHeaderProps {
  selectedUser: ChatUserType;
}

const ChatHeader = ({ selectedUser }: ChatHeaderProps) => {
  return (
    <>
      <div className="d-flex py-2 align-items-center bg-white">
        <div className="profile-data d-flex align-items-center">
          <div className="pe-3 d-flex align-items-center justify-content-center my-auto align-content-center bg-white">
            <a
              href={void(0)}
              className="d-xs-flex justify-content-center"
            >
              <span className="back-btn">
                <img src={BackBtn} alt="Back btn" />
              </span>
            </a>
          </div>
          <img
            src={selectedUser.avatar}
            className="me-2 rounded-circle"
            height="48"
            alt=""
          />
          <div className="row align-items-center my-auto">
            <h5 className="mt-0 mb-0">{selectedUser.name}</h5>
            <p className="mb-0 mt-0">Online</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatHeader;
