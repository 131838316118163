import React, {
  Fragment,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";

// Third Party
import { Card } from "react-bootstrap";
import { useLocalStorage } from "hooks/useLocalStorage";
// Components
import ChatHistoryComponent from "components/ChatHistory";
import ChatHeader from "components/ChatHeader";
import ChatFooter from "components/ChatFooter";
// Axios
import { get, post } from "Service/apiCall";
// Css
import "assets/styles/ChatArea.css";
// Interfaces
import { ChatUserType } from "interfaces/ChatUserType";
import { NewMessage } from "interfaces/NewMessage";
import { MessageItem } from "interfaces/MessageItem";
import { ChatGPTHistory } from "interfaces/ChatGPTHistory";
// Images
import avatar2 from "assets/images/avatar-1.jpg";
import avatar10 from "assets/images/MasMoneyLogo.png";
import avatar1 from "assets/images/avatar-1.jpg";
// Utils
import { getCurrentTime } from "utils/utils";
import FooterMessage from "components/FooterMessage";

const otherUser: string = window.location.href.split("/")[4];

export interface ChatMessage {
  id: number;
  day: string;
  messages: MessageItem[];
}

export const MASMONEY_COACH: ChatUserType = {
  id: 2,
  name: "Más Money Coach",
  avatar: avatar10,
  userStatus: "online",
};

export interface userData {
  id: number;
  asesor: string;
}

interface DataFromWS {
  id: number;
  user_id: number;
  message: string;
  max_chat_messages: number | null;
  chat_messages_used: number | null;
}

// Socket Setup
const PROTOCOL = window.location.protocol === "https:" ? "wss" : "ws";

const ChatToUser: React.FC = () => {
  const [aiThinking, setAiThinking] = useState(false);
  const [asesorLoader, setAsesorLoader] = useState(false);
  const [isAsesor, setIsAsesor] = useState(false);
  const [waitingAsesorResponse, setWaitingAsesorResponse] = useState(false);
  const [gptAnswer, setGptAnswer] = useState("");

  const yesterdayMessages: MessageItem[] = [];
  const todayMessages: MessageItem[] = [];
  const messagesByDate: { [date: string]: MessageItem[] } = {};

  const [results, setResults] = useState<any>([]);
  const [next, setNext] = useState<string | null>(null);
  const [hasMore, setHasMore] = useState(true);
  const [historial, setHistorial] = useState<ChatMessage[]>([]);
  const [userData, setUserData] = useState<userData | null>(null);
  const [messageCount, setMessageCount] = useLocalStorage("messageCount", 0);
  const [maxChatMessages, setMaxChatMessages] = useState<number | null>(-100);
  const [comesFromWS, setComesFromWS] = useState(false);
  const chatContainerRef = useRef<HTMLDivElement | null>(null);
  const chatSocketRef = useRef<WebSocket | null>(null);
  const [JIMMY, SETJIMMY] = useState({
    id: 9,
    name: "Jimmy",
    avatar: avatar2,
  });

  const updateHistory = useCallback(
    (data: DataFromWS) => {
      let newUserMessages: any = [];
      let newHistorial: any = [];
      if (historial.length > 0) {
        newUserMessages = [...historial[0].messages];
        newHistorial = [...historial];
      }
      if (userData?.id !== data.user_id) {
        // userData es quien recibe el mensaje, y data.user_id es quien envia el mensaje
        newUserMessages.unshift({
          id: data.id,
          from: MASMONEY_COACH,
          to: toUser,
          messages: [{ type: "text", value: data.message }],
          sendOn: getCurrentTime(),
        });

        if (newHistorial.length > 0) {
          newHistorial[0].messages = newUserMessages;
        } else {
          newHistorial[0] = {
            id: 3,
            day: "Hoy",
            messages: newUserMessages,
          };
        }
        setHistorial([...newHistorial]);
        if (userData?.asesor) {
          setAsesorLoader(true);
          getGptAnswer(data.message);
          setGptAnswer("");
        } else {
          if ("chat_messages_used" in data) {
            setMessageCount(data.chat_messages_used);
            setMaxChatMessages(data.max_chat_messages);
          }
        }
      }
      let userNameOfLastMsg = historial && historial[0].messages[0].from.name;
      if (userNameOfLastMsg === MASMONEY_COACH.name) {
        setWaitingAsesorResponse(false);
      } else {
        setWaitingAsesorResponse(true);
      }
    },
    [historial]
  );

  useEffect(() => {
    chatSocketRef.current = new WebSocket(
      `${PROTOCOL}://${process.env.REACT_APP_BASE_WS_URL}/ws/chat/${otherUser}/`
    );

    chatSocketRef.current.onclose = function (e) {
      console.error("Chat socket closed unexpectedly");
    };

    return () => {
      chatSocketRef.current?.close();
    };
  }, []);

  useEffect(() => {
    if (chatSocketRef.current) {
      chatSocketRef.current.onmessage = async function (e) {
        const data = JSON.parse(e.data);
        updateHistory(data);
        setComesFromWS(true);
      };
    }
  }, [updateHistory]);

  useEffect(() => {
    if (comesFromWS) {
      chatContainerRef?.current?.scrollTo({
        top: chatContainerRef.current.scrollHeight + 10,
        behavior: "smooth",
      });
      setComesFromWS(false);
    }
  }, [historial, comesFromWS]);

  const toUser = {
    id: 9,
    name: "Shreyu N",
    avatar: avatar1,
  };

  const getGptAnswer = async (question: string) => {
    let payload: any = {
      question: question,
    };

    const data: any = await post(`/api/get-gpt-answer/`, payload);

    setGptAnswer(data.answer);
    setAsesorLoader(false);
  };

  const sendChatMessage = async (message: NewMessage) => {
    setAiThinking(true);
    setGptAnswer("");

    const chatHistoryIsEmpty: boolean = historial.length === 0;
    let newUserMessages: MessageItem[] = [];

    if (!chatHistoryIsEmpty) {
      newUserMessages = [...historial[0].messages];
      newUserMessages.unshift({
        id: historial[0].messages.length + 1,
        from: toUser,
        to: MASMONEY_COACH,
        messages: [{ type: "text", value: message["newMessage"] }],
        sendOn: getCurrentTime(),
      });
    } else {
      newUserMessages.push({
        id: 1,
        from: toUser,
        to: MASMONEY_COACH,
        messages: [{ type: "text", value: message["newMessage"] }],
        sendOn: getCurrentTime(),
      });
    }

    let modifiedChatHistory: any = [];

    if (!chatHistoryIsEmpty) {
      modifiedChatHistory = [...historial].map((record, index) => {
        const test = {
          id: record.id,
          day: record.day,
          messages: index === 0 ? newUserMessages : record.messages,
        };
        return test;
      });
    } else {
      modifiedChatHistory = [
        {
          id: "3",
          day: "Hoy",
          messages: newUserMessages,
        },
      ];
    }

    setHistorial([...modifiedChatHistory]);

    chatSocketRef.current?.send(
      JSON.stringify({
        message: message["newMessage"],
      })
    );

    setAiThinking(false);
  };

  useEffect(() => {
    const fetchOtherUserData = async () => {
      if (userData?.asesor) {
        await getOtherUserInfo();
      }
    };

    fetchOtherUserData();
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      await getUserData();
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      await getMessages();
    };
    fetchData();
  }, [userData]);

  const addMessagesToChatHistory = (
    chatHistory: ChatMessage[],
    todayMessages: MessageItem[],
    yesterdayMessages: MessageItem[],
    messagesByDate: { [date: string]: MessageItem[] }
  ) => {
    if (todayMessages.length > 0) {
      chatHistory.push({
        id: 3,
        day: "Hoy",
        messages: todayMessages,
      });
    }
    if (yesterdayMessages.length > 0) {
      chatHistory.push({
        id: 2,
        day: "Ayer",
        messages: yesterdayMessages,
      });
    }
    for (const date in messagesByDate) {
      chatHistory.push({
        id: parseInt(date),
        day: date.replace(/_/g, "/"),
        messages: messagesByDate[date],
      });
    }
  };

  useEffect(() => {
    let chatHistory: ChatMessage[] = [];
    for (const result of results) {
      const messageTime: number = result.time;
      const messageDate: Date = new Date(messageTime);
      const currentDate: Date = new Date();
      // Convertir la fecha de time a un string en formato 'YYYY-MM-DD'
      const dateString: string = messageDate.toString().slice(0, 10);
      // Convertir la fecha actual a un string en formato 'YYYY-MM-DD'
      const currentDateString: string = currentDate.toString().slice(0, 10);
      let hours = messageDate.getHours();
      let minutes = messageDate.getMinutes();
      let timePeriod = "am";
      if (hours > 12) {
        hours -= 12;
        timePeriod = "pm";
      }
      if (minutes === 0) {
        hours = 12;
      }
      const formattedTime = `${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes} ${timePeriod}`; // E.g. 8:30 am

      if (dateString === currentDateString) {
        // Si la fecha del dato es igual a la fecha actual, es 'hoy'
        if (result.receiverId === userData?.id) {
          todayMessages.push({
            id: result._id,
            messages: [
              {
                type: "text",
                value: result.message,
              },
            ],
            to: JIMMY,
            from: MASMONEY_COACH,
            sendOn: formattedTime,
          });
        } else {
          todayMessages.push({
            id: result._id,
            messages: [
              {
                type: "text",
                value: result.message,
              },
            ],
            to: MASMONEY_COACH,
            from: JIMMY,
            sendOn: formattedTime,
          });
        }
      } else {
        // Si la fecha del dato no es igual a la fecha actual, comprobar si es de ayer o antes de ayer
        if (currentDate.getDate() - messageDate.getDate() === 1) {
          // Si la diferencia en días es igual a 1, el dato es 'ayer'
          if (result.receiverId === userData?.id) {
            yesterdayMessages.push({
              id: result._id,
              messages: [
                {
                  type: "text",
                  value: result.message,
                },
              ],
              to: JIMMY,
              from: MASMONEY_COACH,
              sendOn: formattedTime,
            });
          } else {
            yesterdayMessages.push({
              id: result._id,
              messages: [
                {
                  type: "text",
                  value: result.message,
                },
              ],
              to: MASMONEY_COACH,
              from: JIMMY,
              sendOn: formattedTime,
            });
          }
        } else {
          // Si la diferencia en días es mayor a 1, el dato es de una fecha anterior a antes de ayer
          const messageTime: number = result.time;
          const messageDate: Date = new Date(messageTime);
          // Convertir la fecha de time a un string en formato 'DD_MM_YYYY'
          const dateString: string = messageDate
            .toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
            .replace(/\//g, "_")
            .replace(/(\d{2})_(\d{2})_(\d{4})/, "$2_$1_$3");
          // Agregar el mensaje al array correspondiente a la fecha
          if (!messagesByDate[dateString]) {
            messagesByDate[dateString] = [];
          }
          if (result.receiverId === userData?.id) {
            messagesByDate[dateString].push({
              id: result._id + 1,
              messages: [
                {
                  type: "text",
                  value: result.message,
                },
              ],
              to: JIMMY,
              from: MASMONEY_COACH,
              sendOn: formattedTime,
            });
          } else {
            messagesByDate[dateString].push({
              id: result._id,
              messages: [
                {
                  type: "text",
                  value: result.message,
                },
              ],
              to: MASMONEY_COACH,
              from: JIMMY,
              sendOn: formattedTime,
            });
          }
        }
      }
    }

    // Agregar los mensajes por fecha a CHATHISTORY
    addMessagesToChatHistory(
      chatHistory,
      todayMessages,
      yesterdayMessages,
      messagesByDate
    );

    setHistorial(chatHistory);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results]);

  const getNextMessages = async () => {
    if (next) {
      const data: ChatGPTHistory = await get(next);

      setHasMore(data.next ? true : false);
      setResults((prevResults: any) => [...prevResults, ...data.results]);
      setNext(data.next);
    } else {
      setHasMore(false);
    }
  };

  const getOtherUserInfo = async () => {
    const data: any = await get(`/api/get-other-user-info/${otherUser}`);

    SETJIMMY({
      id: 9,
      name: data.user_email,
      avatar: avatar2,
    });
  };

  const getMessages = async () => {
    const data: ChatGPTHistory = await get(
      `/api/get-real-chat-history/${otherUser}`
    );

    setHasMore(data.next ? true : false);
    setResults(data.results || []);
    setNext(data.next);

    if (data.results.length > 0) {
      if (data.results[0].senderId === userData?.id) {
        setWaitingAsesorResponse(true);
      } else {
        setWaitingAsesorResponse(false);
      }
    }
  };

  const getUserData = async () => {
    const user: any = await get(`/api/profile`);
    setUserData(user);
    user?.asesor ? setIsAsesor(true) : setIsAsesor(false);

    if ("chat_messages_used" in user) {
      setMessageCount(user.chat_messages_used);
      setMaxChatMessages(user.max_chat_messages);
    }
  };

  const isUserLoggedIn = userData && userData.id >= 0;

  const renderFooter = () => {
    if (isUserLoggedIn && messageCount === maxChatMessages) {
      return <FooterMessage messageCount={messageCount} />;
    }
    return (
      <ChatFooter
        aiThinking={aiThinking}
        sendChatMessage={sendChatMessage}
        messageCount={messageCount}
        gptAnswer={gptAnswer}
        asesorLoader={asesorLoader}
        setMessageCount={setMessageCount}
        maxChatMessages={maxChatMessages}
        isAsesor={isAsesor}
        waitingAsesorResponse={waitingAsesorResponse}
        historial={historial.length}
      />
    );
  };

  return (
    <Fragment>
      <div style={{ backgroundColor: "#EAF6FE", height: "100vh" }}>
        <Card
          className="mb-0 border-0"
          id="scrollableDiv"
          style={{
            height: "calc(100vh - 60px)",
            overflow: "auto",
            display: "flex",
            flexDirection: "column-reverse",
          }}
          ref={chatContainerRef}
        >
          <Card.Body>
            <div
              className="card-body chatbot_header border-bottom col-lg-8 mx-auto"
              style={{
                padding: "0 1.25rem",
              }}
            >
              <ChatHeader
                selectedUser={userData?.asesor ? JIMMY : MASMONEY_COACH}
              />
            </div>

            <div className="mt-5 chat-area">
              <ChatHistoryComponent
                getNextMessages={getNextMessages}
                historial={historial}
                hasMore={hasMore}
                messageCount={messageCount}
                isUserLoggedIn={isUserLoggedIn}
                aiThinking={null}
                maxChatMessages={maxChatMessages}
                scrollRef={chatContainerRef}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      {renderFooter()}
    </Fragment>
  );
};
export { ChatToUser };
