import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Loader from "components/Loader";
import FormInput from "components/FormInput";
import SendMessageIcon from "../assets/images/Icons/SendMessageIcon.svg";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

import { CHAT_MAX_INPUT_LENGTH, INPUT_ROW_HEIGHT } from "utils/constants";

const schemaResolver = yupResolver(
  yup.object().shape({
    newMessage: yup.string().required("Please enter your messsage"),
  })
);

interface ChatFooterProps {
  sendChatMessage: any;
  aiThinking: boolean;
  messageCount: any;
  setMessageCount: any;
  gptAnswer: any;
  asesorLoader: any;
  maxChatMessages: number | null;
  isAsesor: boolean;
  waitingAsesorResponse: boolean;
  historial: number | null;
}

const ChatFooter = ({
  sendChatMessage,
  gptAnswer,
  asesorLoader,
  aiThinking,
  messageCount,
  setMessageCount,
  maxChatMessages,
  isAsesor,
  waitingAsesorResponse,
  historial,
}: ChatFooterProps) => {
  const methods = useForm({ resolver: schemaResolver });
  const [wordCounter, setWordCounter] = useState<number>(0);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = methods;

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setWordCounter(event.currentTarget.value.length);
    setValue("newMessage", event.currentTarget.value);

    const textarea = event.currentTarget;
    // Adjust the rows attribute based on the content's height
    const contentRows = Math.min(textarea.scrollHeight / INPUT_ROW_HEIGHT);
    if (contentRows <= 3.3 && event.currentTarget.value !== "") {
      textarea.style.height = "auto"; // Reset the height to calculate the new height
      textarea.style.height = `${textarea.scrollHeight}px`; // Set the height to fit the content
      textarea.setAttribute("rows", String(contentRows));
    } else if (contentRows <= 3.3 && event.currentTarget.value.length <= 1) {
      textarea.style.height = "auto";
      textarea.setAttribute("rows", "1");
    }
  };

  useEffect(() => {
    if (gptAnswer) {
      setValue("newMessage", gptAnswer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gptAnswer]);

  const handleFormSubmit = (props: any): void => {
    sendChatMessage(props);
    setWordCounter(0);
    if (!maxChatMessages) {
      setMessageCount(messageCount + 1);
    }
    reset();

    const textarea = document.querySelector(
      "textarea[name='newMessage']"
    ) as HTMLTextAreaElement;
    if (textarea) {
      textarea.style.height = "auto";
      textarea.setAttribute("rows", "1");
    }
  };

  return (
    <div className="bg-white chatbot_footer col-lg-8 mx-auto border-start border-end">
      <div className="bg-light p-3 rounded">
        <form
          noValidate
          name="chat-form"
          id="chat-form"
          onSubmit={handleSubmit(handleFormSubmit)}
        >
          <div className="d-flex align-items-center">
            <div className="col me-2">
              <FormInput
                type="textarea"
                name="newMessage"
                className="border-0"
                placeholder="Hazme una pregunta..."
                register={register}
                key="newMessage"
                errors={errors}
                control={control}
                autoComplete="off"
                onChange={handleInputChange}
                maxLength={CHAT_MAX_INPUT_LENGTH}
                rows={1}
                style={{
                  resize: "none",
                }}
              />
            </div>
            <div className="col-sm-auto">
              <div className="btn-group">
                <button
                  type="submit"
                  className="btn chat-send d-flex align-items-center justify-content-center"
                  style={{
                    borderRadius: "50%",
                    width: 50,
                    height: 50,
                    backgroundColor: "#0D56E2",
                  }}
                >
                  <img src={SendMessageIcon} alt="Send message icon" />
                </button>
              </div>
            </div>
          </div>
          {aiThinking && <Loader />}
          {!isAsesor && maxChatMessages && messageCount >= maxChatMessages && (
            <div
              className="preloader d-flex justify-content-center align-items-center"
              id="preloader"
            ></div>
          )}
          {asesorLoader && (
            <div className="preloader" id="preloader">
              <p className="text-center">Obteniendo respuesta...</p>
              <div className="status" id="status">
                <div className="spinner"></div>
              </div>
            </div>
          )}
          {!isAsesor && waitingAsesorResponse && (
            <div
              className="preloader d-flex justify-content-center align-items-center"
              id="preloader"
            >
              <p className="text-center mb-0">
                Esperando respuesta del asesor...
              </p>
            </div>
          )}
          {((isAsesor && waitingAsesorResponse) ||
            (isAsesor && historial === 0)) && (
            <div
              className="preloader d-flex justify-content-center align-items-center"
              id="preloader"
            >
              <p className="text-center mb-0">
                Esperando consulta del usuario...
              </p>
            </div>
          )}
        </form>
        <div
          style={{ position: "absolute", right: "86px" }}
          className="counter-container"
        >
          <span className="counter">
            {wordCounter}/{CHAT_MAX_INPUT_LENGTH}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatFooter;
