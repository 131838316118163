import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Markup } from "interweave";
import { get } from "Service/apiCall";
import "./index.css";

const ChatUsage = () => {
  const [chatUsage, setChatUsage] = useState("");

  const fetchChatUsage = async () => {
    try {
      const response = await get<{ text: string }[]>("/api/get-chat-usage/");
      const chatUsage = response[0].text;
      setChatUsage(chatUsage);
    } catch (error: any) {
      console.error("Error al obtener los datos:", error);
    }
  };

  useEffect(() => {
    fetchChatUsage();
  }, []);

  return (
    <div className="chat-usage d-flex justify-content-center align-items-center my-5 container">
      <Card
        className="p-4"
        style={{
          borderRadius: "10px",
          boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Markup content={chatUsage} />
      </Card>
    </div>
  );
};

export default ChatUsage;
