import { get } from "../Service/apiCall";
import { ChatGPTHistory } from "interfaces/ChatGPTHistory";

const getChatHistory = async (): Promise<ChatGPTHistory> => {
  const data: ChatGPTHistory = await get(`/api/get-chat-history`);

  return data;
};

export default getChatHistory;
